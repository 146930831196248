<template>
  <div class="salary">
    <Header title="Зарплата" />

    <div class="wrapper">
      <div class="salary__person">
        <div class="container">
          <div class="salary__inner">
            <p class="salary__name">{{ courier }}</p>
            <img src="@/assets/img/refresh.png" alt="" class="salary__img" @click=refresh>
          </div>
        </div>      
      </div>
      <div class="order__detail">
        <div class="container">
          <ul>
            <li class="order__detail-item">
              <span class="order__detail-gray">Объект расчётов</span>
              <span class="order__detail-gray order__detail-right">Сумма</span>
            </li>
            <li class="order__detail-item" v-for="(order, orderIndex) in salary" :key="orderIndex">
              <p class="order__detail-left" :class="{'strong': order.ВажныйОбъект}">{{ order.ОбъектРасчетов }}</p>
              <p class="order__detail-right order__detail-count">
                <span>{{ order.Сумма }}</span>
              </p>
            </li>
          </ul>  
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Salary',
   components: {
    Header
  },
  computed: {
    courier () {
      return this.$store.state.courier  
    },
    salary () {
      return this.$store.state.salary
    }
  },
  created () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.$store.dispatch('CHECK_AUTH', localStorage.getItem('pin'))
        .then(() => {
          this.$store.dispatch('GET_SALARY')
        })     
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  margin: 0px auto;
  background-color: #fff;
}
.container {
  margin: 0 10px;
}
.salary {
  min-height: 100vh;
  background-color: #f2f2f2;
  &__person {
    background-color: #fff;
    padding: 16px 0px;
  }
  &__name {
    font-size: 18px;
    font-weight: 500;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  &__img {
    cursor: pointer;
  }
}
.order {
  &__detail {
    background-color: #f2f2f2;
    font-size: 14px;
    &-item {
      padding: 12px 10px 10px;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:last-of-type {
        border-top: 1px solid #000;
        border-bottom: none;
        font-weight: 700;
      }  
    }
    &-right {
      width: 90px;
      text-align: right;
    }
    &-left {
      width: calc(100% - 100px);
      margin-right: 10px;
    }
    &-count {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-gray {
      color: #7e7e7e;
    }
    &-overall {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 10px 10px;
      border-top: 1px solid #000;   
      position: relative;
      top: -1px;   
    }
    &-sum {
      width: calc(100% - 86px);
      margin-right: 26px;
    }
  }
}
.strong {
  font-weight: 700;
}
</style>
